const plans = [
    {
      title: 'Silver Plan',
      oldPrice: '₹10,000',
      newPrice: '₹7,999',
      description: 'Perfect for small businesses starting online.',
      features: [
        'User-Friendly Design',
        'Mobile Responsive',
        'Quick Setup',
        'Basic Support',
      ],
    },
    {
      title: 'Gold Plan',
      oldPrice: '₹17,000',
      newPrice: '₹13,999',
      description:  'Ideal for growing businesses seeking dynamic solutions.',
      features: [
        'Interactive Design',
        'Customizable Pages',
        'Enhanced SEO Optimization',
        'Priority Support',
      ],
    },
    {
      title: 'Diamond Plan',
      oldPrice: '₹???',
      newPrice: 'Price On Request',
      description:  'Custom solutions for ambitious projects.',
      features: [
        'Fully Custom Designs',
        'Advanced Integrations',
        'Analytics and Reports',
        'Dedicated Account Manager',
      ],
    },
    
  ];
  export default plans;