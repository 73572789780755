import React from 'react'
import './Footer.css'
import './Team.css';
import User from '../image/userid.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLinkedin } from '@fortawesome/free-brands-svg-icons';
const Footer = () => {
  return (
    <>
     
  <section class="info_section layout_padding2">
    <div class="container">
      <div class="row">
      <div class="col-md-6 col-lg-3 info_col ">
<h4>Developer</h4>
<section className="team_section layout_padding">
<div className="container-fluid">
<div className="team_container">
<div className="row">
  <div className="myinfo" >
              <div className="box ">
                <div className="img-box">
                  <img src={User} className="img1" alt=""/>
                </div>
                <div className="detail-box">
                  <h5>
                    Ankit Kumar
                  </h5>
                  <p>
                    Website developed and managed by me
                  </p>
                </div>
                <div className="social_box">
                 <span></span>
                  <a href="https://www.linkedin.com/in/ankit-kumar-a174ab321" target="_blank" rel="noopener">
                   <FontAwesomeIcon icon={faLinkedin} style={{color: "#f5f5f5",}} />
                
                  </a>
                  <span></span>
                </div>
              </div>
            </div>

</div>
</div>
</div>
</section>
</div>
       
        <div class="col-md-6 col-lg-3 info_col">
          <div class="info_detail">
            <h4>
              Info
            </h4>
            <p>
            WebOcean offers expert website development services and web courses designed to empower businesses and individuals. We create custom websites tailored to your needs, ensuring a strong online presence. Our comprehensive web development and digital marketing courses equip you with the skills to build, manage, and market your website effectively.
               </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-2 mx-auto info_col">
          <div class="info_link_box">
            <h4>
              Links
            </h4>
            <div class="info_links">
            <a href="https://www.facebook.com/idealeyesofficial/" class="active" target="_blank" rel="noopener"> Facebook</a>
            <a href="https://www.youtube.com/channel/UC82v7aIbE1gcucmF1a2jofA" target="_blank" rel="noopener" class="active">YouTube</a>
            <a href="https://www.instagram.com/idealeyesindia/?hl=en" target="_blank" rel="noopener" class="active"> Instagram</a>
             <a href="https://www.freepik.com/free-photos-vectors/background" target="_blank" rel="noopener" class="active">Image by Freepik</a>
            
             
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 info_col">
          <div class="info_contact">
            <h4>
              Address
            </h4>
            <div class="contact_link_box">
              <a href="#">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span>
                Opp: P.N. Anglo School, Pratima Complex, Naya Tola, Bhikhana Pahari, Patna, Bihar 800004
                </span>
              </a>
              <a href="#">
                <i class="fa fa-phone" aria-hidden="true"></i>
                <span>
                  Call 7759810532
                </span>
              </a>
             
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </section> 
    </>
  )
}

export default Footer
