
const coursePlans = [
    {
      title: 'Digital Marketing Course',
      oldPrice: '₹30,000',
      newPrice: '₹4,999',
      description: 'Learn the fundamentals of digital marketing',
      features: [
        'SEO Basics',
        'Social Media Marketing (1 Platform)',
        'Introduction to Google Ads',
        'Adverstising tools and its web analytics',
        'Website hosting using wordpress',
        'Project',
        'Certification on Completion',
      ],
    },
    
    {
      title: 'Web Developer Course',
      oldPrice: '₹40,000',
      newPrice: '₹22,499',
      description: 'Learn web development from scratch',
      features: [
        'MS-office',
        'C & Java',
        'HTML, CSS, JavaScript',
        'Responsive Web Design',
        'Python/Oracle',
        'Php & Mysql',
        'Basic Frontend Project Work',
        '100% job assistance',
        'Certification on Completion',
      ],
    },
    
    
    {
      title: 'Digital Mastery',
      oldPrice: '₹50,000',
      newPrice: '₹24,999',
      description: 'Learn web development from scratch',
      features: [
        'MS-office',
        'C & Java',
        'HTML, CSS, JavaScript',
        'Responsive Web Design',
        'Python/Oracle',
        'Php & Mysql',
        'Basic Frontend Project Work',
        '100% job assistance',
        'Certification on Completion',
        'FREE :- Digital marketing',
        'FREE :-English Spoken',
      ],
    },
  ];
  
  export default coursePlans;
  
