import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Review.css';
const MyReview = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviews = [
    { id: 1, name: 'Aarav Gupta', review: 'The web development course was hands-on and easy to follow. I built my first website in weeks!', rating: 5 },
    { id: 2, name: 'Isha Sharma', review: 'Amazing insights into digital marketing. The strategies I learned are already helping my business grow.', rating: 5 },
    { id: 3, name: 'Rohan Patel', review: 'Clear and practical web development lessons. The instructor was very supportive.', rating: 4 },
    { id: 4, name: 'Meera ', review: 'The digital marketing course covered everything from SEO to social media ads. Highly recommend it.', rating: 4 },
    { id: 5, name: 'Kabir Joshi', review: 'I got a solid foundation in web development. The examples were real-world and practical.', rating: 5 },
    { id: 6, name: 'Ananya Rao', review: 'A great digital marketing course with real-world projects and useful tips for branding.', rating: 4 },
    { id: 7, name: 'Vikram Singh', review: 'The course content is excellent. It made learning web development enjoyable and effective.', rating: 5 },
    { id: 8, name: 'Sanya', review: 'Loved the digital marketing strategies shared in the course. Easy to understand and implement.', rating: 5 },
  ];
  

  return (
    <div className="customer-review-slider">
      <h2>Customer Reviews</h2>
      <Slider {...settings}  className="slider-with-gap">
        {reviews.map((review) => (
          <div className="item" key={review.id}>
            <div className="review">
              <h3>{review.name}</h3>
              <p>{review.review}</p>
              <p>Rating: {Array(review.rating).fill('⭐').join('')}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MyReview;
