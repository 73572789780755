const digitalMarketingPlans = [
  {
    title: 'Standard Marketing Package',
    tagline: 'Start Your Digital Journey',
    description: 'Perfect for small businesses entering the digital space.',
    newPrice: 'Starts at ₹12,999',
    features: [
      'Social Media Management',
      'Basic SEO Optimization',
      'Performance Reports',
      'Email Marketing Setup',
    ],
  },
  {
    title: 'Premium Marketing Package',
    tagline: 'Grow and Engage Your Audience',
    description: 'Designed for businesses aiming for impactful growth.',
    newPrice: 'Starts at ₹24,999',
    features: [
      'Multi-Platform Management',
      'Advanced SEO Strategies',
      'Content Creation',
      'Analytics and Insights',
    ],
  },
  {
    title: 'Custom Marketing Package',
    tagline: 'Tailored for Maximum Impact',
    description: 'Fully customized marketing solutions for unique needs.',
    newPrice: 'Price on Request',
    features: [
      'Custom Strategies',
      'Comprehensive SEO',
      'Ad Campaign Management',
      'Content and Social Media Solutions',
    ],
  },
];

export default digitalMarketingPlans;
