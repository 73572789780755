import React from 'react';
import Slider from 'react-slick';
import './Review.css';
import Image from '../image/by.png';
import Image2 from '../image/id.png';
import Image3 from '../image/imh.jpg';

// Sample data for reviews
const works = [
  { id: 1, image: Image, name: 'Ankit', review: 'biharyatra.in', rating: 5,domain:'https://www.biharyatra.in' ,add :'click' },
  { id: 2, image: Image2, name: 'Anish', review: 'idealeyes.org', rating: 5,domain:'https://www.idealeyes.org' ,add :'click' },
  { id: 3, image: Image3, name: 'Ankit', review: 'thewebocean.com', rating: 5,domain:'http://www.thewebocean.com/' ,add :'click' },
  
 
];

const OurWork = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="customer-review-slider">
      <h2>Our Works</h2>
      <Slider {...settings} className="slider-with-gap">
        {works.map((work) => (
          <div className="testimonial" key={work.id}>
            <span className="open quote">“</span>
            <div className="image">
              <div className="clip"></div>
              <img src={work.image} alt="work" />
            </div>
            <p className='workinfo'>{work.review}</p>
            <p><a href={work.domain} target="_blank" rel="noopener">{work.add}</a></p>
            <div className="source">		
              <span>{work.name}</span>
            </div>
            <span className="close quote">”</span>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OurWork;
